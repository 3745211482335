
import react, {useState, useRef} from 'react'
import { animateScroll as scroll} from  'react-scroll'
import emailjs from 'emailjs-com';

import gsap from 'gsap';
import './index.scss'


import Imagen1 from "../img/logoHeader1.png";
import Imagen2 from "../img/logoHeader2.png";
import Imagen3 from "../img/logoFooter.svg";
import Imagen3White from "../img/logoFooterWhite.png";
import Imagen4 from "../img/triangulo.png";
import Imagen5 from "../img/footer1.png";
import Imagen6 from "../img/footer2.png";
import Whats from "../img/whats.svg";
import Fondo from "../img/fondoD2.png";
import { DURATION_CHANGE } from 'video-react/lib/actions/video';


const Mobil = () => {



    const frmContact = { reply_to:'webform@m2c1solutions.com', 
            from_name:'',
            nombreDato:'', 
            emailDato:'',     
            telefonoDato:'', 
            notaDato:'' 
        };
    
    const [contact,setContact] = useState({ reply_to:'webform@m2c1solutions.com', 
    from_name:'',
    nombreDato:'', 
    emailDato:'',     
    telefonoDato:'', 
    notaDato:'' 
});
    const [showMessage, setShowMessage] = useState(false);
  








    let duracion = 0.4
    const tl = gsap.timeline()
    const Message = useRef(null);
    const MostrarFiltro = useRef(null);
    const bottomRef = useRef(null);
    const ventanaEscritorio = useRef(null);
    const ventanaEscritorioDos = useRef(null);


   
 
    function Envio(){

        let nombre = document.getElementById("Name").value
        let email = document.getElementById("Email").value
        let telefono = document.getElementById("Telefono").value
        let Nota  = document.getElementById("Nota").value
        

        if(nombre === "" || nombre === null){
           alert("Please, add your name")
           return 0
        }else if(email === "" || email === null){
            alert("Please, add your email")
            return 0
        }else if(telefono === "" || telefono === null){
            alert( "Please, add a phone number")
            return 0
        }
/*
        from_name:'',
        nombreDato:'', 
        emailDato:'',     
        telefonoDato:'', 
        notaDato:'' 
*/
console.log(nombre)
 
        setContact({...contact, nombreDato:'nombre'});
       
            console.log("Datos --->" , contact)
        let Datos = {
            reply_to:'webform@m2c1solutions.com', 
            from_name: nombre,
            nombreDato: nombre, 
            emailDato: email,     
            telefonoDato: telefono, 
            notaDato:Nota 
        }
         
         emailjs.send('default_service','template_tsis3i9', Datos, 'vpknnQYDYUkvHa1uW')
         .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                   
         }, (err) => {
                    console.log('FAILED...', err);
         });



         
         setTimeout(Aparecer(), 5000);

    }




    function Aparecer(){

        tl.add('openbutton')
        .to(Message.current, { display:"flex" , duration:duracion , ease: 'circ.out'}, 'openbutton')
        .to(MostrarFiltro.current, { zIndex: 5 , duration:duracion , ease: 'circ.out'}, 'openbutton')
    
        scroll.scrollToBottom();

    }

    //webform@m2c1solutions.com
    function Llamar(){
        window.open('tel:2034848800')
    }

    return(
       
        <div className='containerGeneral'>
            <div className="containerMobil">
                <div className="logo">
                    <img src={Imagen1}></img>
                </div>
                <div className="titulo">            
                    <img src={Imagen2}></img>
                </div>
                <div className="formulario">
                    
                    <div className="filtro" ref={MostrarFiltro}></div>

                    <div className="contenedor">
                        <input type="text" placeholder="NAME" id="Name" />
                        <div className='triangulo'>
                            <img src={Imagen4}></img>
                        </div>
                    </div>
                    <div className="contenedor">
                        <input type="email" placeholder="@EMAIL" id='Email' />
                        <div className='triangulo'>
                            <img src={Imagen4}></img>
                        </div>
                    </div>
                    <div className="contenedor">
                        <input type="tel" placeholder="TEL" id='Telefono' />
                        <div className='triangulo'>
                            <img src={Imagen4}></img>
                        </div>
                    </div>
                    <div className="contenedor">
                        <input type="text" placeholder="NOTES" id='Nota' />
                        <div className='triangulo'>
                            <img src={Imagen4}></img>
                        </div>
                    </div>
                                    
                    <div className="botom" onClick={() => Envio()} >SUBMIT</div>

                    

                </div>
                <div className="footer">           
                    <div className="tituloFooter">
                        <img src={Imagen5}></img>
                    </div>
                    
                        <a href='https://api.whatsapp.com/send?phone=12034848800&text=Hi,%20I%20want%20more%20information' target="_blank">
                        <div className="numeroFooter" >
                            <img src={Whats} className="Whats"></img>
                            <img src={Imagen6}></img>
                        </div>
                    </a>
                </div>
                <div className="messageFooter" ref={Message} id="Message">
                    <div className="titulo">THANK YOU</div>
                    <div className="texto">We’ll be in touch</div>
                </div>

                


                <div className="logoFooter" id="Footer" ref={bottomRef}>
                    <a href='https://tbestudio.com/' target="_blank"><img src={Imagen3} ></img></a>
                </div>
              
               
                <div className="logoFooterDesktop" id="Footer" ref={bottomRef}>                
                    
                    <a href='https://tbestudio.com/' target="_blank"><img src={Imagen3White}  ></img></a>          
                </div>
                
            </div>

            <div className="containerDesktopOne" id="containerDesktopOne" ref={ventanaEscritorio}>
                         
            </div>
        </div>
       
    )
}

export default Mobil



/**
 * 
                <div className="sectionTwo"></div>
                <div className="sectionThree"></div>
                <div className="sectionFour"></div>

                <div className="sectionFive"></div>
                <div className="sectionSix"></div>
                <div className="sectionSeven"></div>
                <div className="sectionEight"></div>       
 
 */