import React, { Fragment }  from 'react';
import { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';

import Normalize from 'react-normalize';

//import VideoLooper from 'react-video-looper'
//import sampleVideo from './M2C1.mp4'

import TBE from './tbe';


import Logo from './logo.svg'
import { Video } from 'video-react';


import Mobil from './components/home/index.jsx'
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <Fragment>
    <Normalize />
    <div>     
      <div className="EscritorioMobil">
        <Mobil></Mobil>
      </div>
   
    </div>
    
    </Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


/**
 
        <div className='container'>
          <div className='video'  >
            <VideoLooper  source={sampleVideo}  muted={true} start={0} end={26} loopCount={3000}  />
          </div>      
          <div className='containerLogo'>
            <img src={Logo} className="Imagen"></img>
            <div className='Direccion'>
                <label> OFFICE 203-484-8800</label>
            </div>
          </div>
          <div className='filtro'></div>
          <div className='containerDesign'>
            <TBE />
          </div>       
        </div>        



 */